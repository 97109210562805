import React, {useCallback, useRef, useState} from 'react';
import './App.css';

const randomColor = require('randomcolor');

function App() {
  const audioRef = useRef(null);
  const goidaRef = useRef(null);

  const AudioContext = window.AudioContext;
  if (/iPad|iPhone|Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
    let audioCtx = new AudioContext();
  }

  const [count, setCount] = useState(0);
  const [colors, updateColors] = useState(['#bb2a1b', '#e5dbdb']);

  const onclick = useCallback(async () => {
    if (!audioRef.current) return null;
    const audio = audioRef.current as HTMLAudioElement;

    if (navigator.vibrate) navigator.vibrate(10);
    setCount((current) => current + 1);

    if (count > 0 && (count + 1) % 10 === 0) {
      updateColors(randomColor({count: 2}));

      if (!goidaRef.current) return null;
      const goida = goidaRef.current as HTMLAudioElement;

      if (goida.paused) await goida.play();
      else {
        await goida.pause();
        goida.currentTime = 0;
        await goida.play();
      }
    }

    if (audio.paused) await audio.play();
    else {
      await audio.pause();
      audio.currentTime = 0;
      await audio.play();
    }
  }, [count]);

  return (
      <div className="App">
        <header className="App-header" style={{
          backgroundColor: colors[1]
        }}>
          <div
              onClick={onclick}
              style={{
                backgroundColor: colors[0]
              }}
              className="App-link"
          >
            <span
              className="counter"
              style={{
                color: colors[1]
              }}
          >
            {count}
          </span></div>
        </header>
        <audio ref={audioRef} src="/zvuk.mp3"></audio>
        <audio ref={goidaRef} src="/goida.mp3"></audio>

      </div>
  );
}

export default App;
